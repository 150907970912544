import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import ComingSoon from './pages/Home/comingSoon';
import OurTeam from './pages/Home/ourTeam';
import Contact from './pages/Home/contact';
import MentalHealthC from './components/MentalHealth/MentalHealth';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/soon" element={<ComingSoon />} />
          <Route path="/about" element={<OurTeam />} />
          <Route path="/services" element={<MentalHealthC />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
