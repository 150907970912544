import React, { useState, useEffect } from 'react';
import './VideoBackground.css';
import { useNavigate } from 'react-router-dom';
import videoSrc from '../../logo/homepage_video.mp4';

interface FormData {
  email: string;
  childAgeRange: string;
  teamPreference: string;
  frequency: string;
  sessionPreference: string;
  maxPeople: string;
  playSupportPreference: string;
  careNeed: string;
  preferredTime: string;
  location: string;
  virtualSupport: string;
}

const VideoBackground: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    childAgeRange: '',
    teamPreference: '',
    frequency: '',
    sessionPreference: '',
    maxPeople: '',
    playSupportPreference: '',
    careNeed: '',
    preferredTime: '',
    location: '',
    virtualSupport: '',
  });
  const [submitted, setSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  useEffect(() => {
    const videoBackground = document.querySelector('.video-background') as HTMLVideoElement;
    if (videoBackground) {
      videoBackground.play().catch(err => console.log("Video play failed:", err));
    }
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsConsentGiven(e.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form data before submission:', formData);
    if (!isConsentGiven) {
      alert('Please agree to the privacy policy before submitting.');
      return;
    }

    const functionUrl = `${process.env.REACT_APP_API_URL}/submit-form`;
    console.log('Sending POST request to:', functionUrl);

    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      console.log('Response status:', response.status);

      const data = await response.text();
      if (response.ok) {
        console.log('Server response data:', data);
        setSubmitted(true);
      } else if (response.status === 409) {
        alert('This email address is already added to our waitlist.');
      } else {
        throw new Error(`Request failed with status ${response.status}: ${data}`);
      }
    } catch (error) {
      console.error('Error during fetch operation:', error);
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (submitted) {
      timer = setTimeout(() => {
        setSubmitted(false);
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [submitted]);

  return (
    <>
      <div className="main-container">
        <div className="video-background-container">
          <video autoPlay loop muted playsInline preload="auto" className="video-background">
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="waitlist-button-container">
          <button className="waitlist-button" onClick={openModal}>
            Join Waitlist
          </button>
          <h2 className="waitlist-container-text">Group Support For Caregiving Together!</h2>
        </div>
      </div>
      {showModal && (
        <div className="modal" role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal} aria-label="Close">&times;</span>
            <div className="waitlist-container">
              <div className="waitlist-header">
                <h3 id="modal-title">Join the Noddle Waitlist</h3>
                <p>Become part of a new wave of social mental wellness for families.</p>
              </div>
              {!submitted ? (
                <form onSubmit={handleSubmit} className="waitlist-form">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                      aria-label="Email"
                    />
                  </div>
                  <div className="form-group">
                    <label>What age is your child(ren)?</label>
                    <select
                      name="childAgeRange"
                      value={formData.childAgeRange}
                      onChange={handleChange}
                      required
                      aria-label="Child(ren)'s Age Range"
                    >
                      <option value="">Select Age Range</option>
                      <option value="under-2">Under 2 years old</option>
                      <option value="2-3">2-3 years old</option>
                      <option value="4-6">4-6 years old</option>
                      <option value="7-8">7-8 years old</option>
                      <option value="9-and-older">9 and older</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>What team does your family need for the in-person group care sessions?</label>
                    <select
                      name="teamPreference"
                      value={formData.teamPreference}
                      onChange={handleChange}
                      required
                      aria-label="Team Preference"
                    >
                      <option value="">Select Team</option>
                      <option value="doctor-nurse-play-therapist">Doctor/Nurse & Play Therapist</option>
                      <option value="fitness-instructor-play-therapist">Fitness Instructor & Play Therapist</option>
                      <option value="play-based-educator-play-therapist">Play-based Educator & Play Therapist</option>
                      <option value="only-play-therapist">Only Play Therapist</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>How often does your family want in-person group care sessions?</label>
                    <select
                      name="frequency"
                      value={formData.frequency}
                      onChange={handleChange}
                      required
                      aria-label="Frequency"
                    >
                      <option value="">Select Frequency</option>
                      <option value="weekly">Weekly</option>
                      <option value="twice-a-month">Twice a month</option>
                      <option value="monthly">Monthly</option>
                      <option value="every-two-months">Every Two Months</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>What is your preference for in-person group care sessions?</label>
                    <select
                      name="sessionPreference"
                      value={formData.sessionPreference}
                      onChange={handleChange}
                      required
                      aria-label="Session Preference"
                    >
                      <option value="">Select Preference</option>
                      <option value="25-min-less-people">25 minute session with less people</option>
                      <option value="50-min-more-people">50 minute session with more people</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>What’s the maximum number of people you feel comfortable with for each in-person group care session?</label>
                    <select
                      name="maxPeople"
                      value={formData.maxPeople}
                      onChange={handleChange}
                      required
                      aria-label="Maximum People"
                    >
                      <option value="">Select Number</option>
                      <option value="12">12</option>
                      <option value="18">18</option>
                      <option value="25">25</option>
                      <option value="36">36</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>What play-based support is your preference for in-person group care sessions?</label>
                    <select
                      name="playSupportPreference"
                      value={formData.playSupportPreference}
                      onChange={handleChange}
                      required
                      aria-label="Play Support Preference"
                    >
                      <option value="">Select Support</option>
                      <option value="yoga-fitness">Yoga / Fitness</option>
                      <option value="art">Art</option>
                      <option value="reading">Reading</option>
                      <option value="writing">Writing</option>
                      <option value="music">Music</option>
                      <option value="dance">Dance</option>
                      <option value="free-play-toys">Free Play with Toys</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>What care need applies most to your family?</label>
                    <select
                      name="careNeed"
                      value={formData.careNeed}
                      onChange={handleChange}
                      required
                      aria-label="Care Need"
                    >
                      <option value="">Select Need</option>
                      <option value="anxiety">Anxiety</option>
                      <option value="depression">Depression</option>
                      <option value="adhd">Attention deficit hyperactivity disorder (ADHD)</option>
                      <option value="grief">Grief</option>
                      <option value="trauma">Trauma</option>
                      <option value="anger-management">Anger Management</option>
                      <option value="autism">Autism</option>
                      <option value="none">None of the above</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>When would you prefer in-person group care sessions?</label>
                    <select
                      name="preferredTime"
                      value={formData.preferredTime}
                      onChange={handleChange}
                      required
                      aria-label="Preferred Time"
                    >
                      <option value="">Select Time</option>
                      <option value="weekday-morning">Weekday Morning</option>
                      <option value="weekday-afternoon">Weekday Afternoon</option>
                      <option value="weekday-evening">Weekday Evening</option>
                      <option value="only-weekend">Only Weekend</option>
                      <option value="any-day">Any Day</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Where in BC do you live?</label>
                    <select
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      required
                      aria-label="Location"
                    >
                      <option value="">Select Location</option>
                      <option value="vancouver">Vancouver</option>
                      <option value="surrey-delta-white-rock">Surrey/Delta/White Rock</option>
                      <option value="richmond">Richmond</option>
                      <option value="tri-cities">Tri-cities</option>
                      <option value="burnaby">Burnaby</option>
                      <option value="new-west">New West</option>
                      <option value="north-shore">North Shore</option>
                      <option value="langley-abbotsford-chilliwack">Langley/Abbotsford/Chilliwack</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>What virtual support are you willing to pay for?</label>
                    <textarea
                      name="virtualSupport"
                      value={formData.virtualSupport}
                      onChange={handleChange}
                      placeholder="Online group chat, Other, None"
                      aria-label="Virtual Support"
                    />
                  </div>
                  <div className="form-group consent-checkbox">
                    <input
                      type="checkbox"
                      id="consent"
                      checked={isConsentGiven}
                      onChange={handleConsentChange}
                      aria-label="Consent"
                    />
                    <label htmlFor="consent">
                      I consent to this waitlist and survey data being collected by Noddle Care Inc.
                      I understand that I can withdraw my consent at any time by contacting Noddle Care Inc.
                    </label>
                  </div>
                  <button type="submit" className="button waitlist-submit-button" disabled={!isConsentGiven}>
                    Submit
                  </button>
                </form>
              ) : (
                <p className="waitlist-confirmation-message">
                  You have been added to the waitlist. We will contact you when we go live.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoBackground;
