import React from 'react';
import './MentalH.css';
import groupImage from '../../assets/group.png';
import playStoryImage from '../../assets/playStory.webp';
import textImage from '../../assets/text.webp';
import Header from '../Header/Header';
import BackButton from '../../components/BackButton/BackButton';
import image1 from '../../assets/image1.webp';
import image2 from '../../assets/image2.webp';
import image3 from '../../assets/image3.webp';
import image4 from '../../assets/image4.webp';
import image5 from '../../assets/image5.webp';
import image6 from '../../assets/image6.webp';
import image7 from '../../assets/image7.webp';


const MentalHealth = () => {
    const cardsContent = [
        {
            title: "Nurturing Family Wellness",
            content: [
                { text: "Our mental health care will be<br />play-based, holistic, and preventive,<br />with community at its core,<br />supporting the challenges families<br />with young children face.", image: null },
                { text: null, image: image7, alt: "Group Support" },
                { text: null, image: groupImage, alt: "Family Support" },
                { text: "We aim to make parenting<br />less stressful and less lonely<br />by integrating in-person group care<br />with online support.", image: null }
            ]
        },
        {
            title: "Holistic Approach",

            content: [
                { text: "Children develop and understand<br />the world around them through play.<br />Our aim is to leverage play during<br />the most pivotal period of <br /> brain development<br />for a more impactful health outcome.", image: null },

                { text: null, image: image3, alt: "Brain Development" },
                { text: null, image: image4, alt: "Holistic Care" },

                { text: "Our ambition is that our group care will be holistic, incorporating physical and <br /> mental health care.<br />We aim to see the whole picture<br />for better mental health outcomes.", image: null }
            ]
        },
        {
            title: "In-Person Group Care Session",

            content: [
                { text: "Through play our team will help families<br />process emotions, <br /> learn new mental health skills<br />and better understand their health.<br /><br />Our team will provide a <br /> group preventive health care<br />check up for the children. Parents/caregivers will participate to help <br /> the children feel comfortable.", image: null },
                { text: null, image: image6, alt: "Preventive Health Care" },
                { text: null, image: image5, alt: "Group Storytelling" },
                { text: "Our team will facilitate group storytelling using children’s books.<br />This will teach parents/caregivers how to use storytelling to support<br />their children’s emotional growth.<br /><br />Our team will facilitate a final <br /> group discussion about the<br /> families’ care needs, both physical and mental health care.<br />", image: null }
            ]
        },
        {
            title: "Virtual Support",

            content: [
                { text: "<strong>Group Chat</strong><br /><br />There will be online chat groups, so <br />families can support each other at home.<br />Families attending the same <br /> in-person care sessions<br />will be in the same online group chat.", image: null },
                { text: null, image: image2, alt: "Text Support" },
                { text: null, image: image1, alt: "Storytelling" },
                { text: "<strong>Storytelling</strong><br /><br />Families can engage in storytelling at home to help process emotions. <br /> <br />By using the app’s speech recognition feature during storytelling, <br /> these narratives will communicate the family's care needs to our team, <br />enhancing our care services.", image: null }
            ]
        }
    ];

    return (
        <div>
            <Header />
            <div className="mental-health-container">
                {cardsContent.map((card, index) => (
                    <div key={index} className="mental-health-card">
                        <h2 className="card-title">{card.title}</h2>
                        {/* <h3 className="card-subtitle">{card.subtitle}</h3> */}
                        <div className="card-content">
                            <div className="content-layout">
                                <div className="text-section">
                                    {card.content[0].text && <p className="card-description" dangerouslySetInnerHTML={{ __html: card.content[0].text }}></p>}
                                </div>
                                <div className="image-section">
                                    {card.content[1].image && <img src={card.content[1].image} alt={card.content[1].alt} className="card-image" />}
                                </div>
                            </div>
                            <div className="content-layout">
                                <div className="image-section">
                                    {card.content[2].image && <img src={card.content[2].image} alt={card.content[2].alt} className="card-image" />}
                                </div>
                                <div className="text-section">
                                    {card.content[3].text && <p className="card-description" dangerouslySetInnerHTML={{ __html: card.content[3].text }}></p>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <BackButton />
            </div>
        </div>
    );
};

export default MentalHealth;