import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.css';

// Define a type for the component props
type BackButtonProps = {
  buttonColor?: string;
  textColor?: string;
};

const BackButton = ({ buttonColor, textColor }: BackButtonProps) => {
  const navigate = useNavigate();

  const goBackHome = () => {
    navigate('/home');
  };

  const style = {
    backgroundColor: buttonColor || '#7D6E9B',
    color: textColor || 'white',
  };

  return (
    <button className="back-button" onClick={goBackHome} style={style}>
      Back to Home
    </button>
  );
};

export default BackButton;
