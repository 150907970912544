import React from 'react';
import Header from '../../components/Header/Header'; 
import './HomePage.css';
import VideoBackground from '../../components/VideoBackground/VideoBackground'; 

const HomePage = () => {
  return (
    <div>
      <Header />
      <main>
      <VideoBackground />

      </main>
    </div>
  );
};

export default HomePage;
