import React, { useState } from 'react';
import './OurTeam.css';
import RebeccaImage from '../../assets/rebecca.jpeg';
import PritiImage from '../../assets/Priti1.png';
import BackButton from '../../components/BackButton/BackButton';
import Header from '../../components/Header/Header';

interface TeamMember {
  name: string;
  title: string;
  image: string;
  description: string;
}

interface BioModalProps {
  member: TeamMember;
  onClose: () => void;
}

const BioModal: React.FC<BioModalProps> = ({ member, onClose }) => {
  return (
    <div className="bio-modal-overlay" onClick={onClose}>
      <div className="bio-modal-content" onClick={e => e.stopPropagation()}>
        <button onClick={onClose} className="bio-modal-close-button">&times;</button>
        <h2>{member.name}</h2>
        <h3>{member.title}</h3>
        <p>{member.description}</p>
      </div>
    </div>
  );
};

const OurTeam: React.FC = () => {
  const [showDetails, setShowDetails] = useState<number | null>(null);

  const teamMembers: TeamMember[] = [
    {
      name: "Rebecca Katz",
      title: "Co-founder and CEO",
      image: RebeccaImage,
      description: "I am the co-founder of Noddle Care, a child and family mental health company. Through my experience working with children in childcare I realized a major problem in the mental healthcare system; therapy is too financially taxing on families and families want support for everyday challenges. The brain has always been fascinating to me, so I knew I wanted to solve this problem. I pursued an Early Childhood Education Diploma because I believe that change should happen from the ground up, by teaching children. I am excited to lead a company that will provide preventive social mental wellness for families. With innovation and empathy, my aim is to make an impact helping families.",
    },
    {
      name: "Priti Yadav",
      title: "Co-Founder and CTO",
      image: PritiImage,
      description: "I'm Priti Yadav, a budding software developer driven by a passion for leveraging technology to create positive change. With a solid foundation in software development, I bring expertise in areas such as AI, machine learning, and web app development. What truly excites me is the opportunity to make a difference in child mental health. I firmly believe in the importance of preventive care and aspire to develop accessible and innovative solutions that promote the emotional well-being of children. Combining my technical skills with my deep interest in child mental health, I am eager to contribute to a team that shares the vision of creating a brighter future for our young ones.",
    }
    // ...other team members
  ];

  const handleShowDetails = (index: number) => {
    setShowDetails(index);
  };

  const handleCloseDetails = () => {
    setShowDetails(null);
  };

  return (
    <div>
      <Header />
      <div className="team-page-container">
        <div className="team-container">
          {teamMembers.map((member, index) => (
            <div className="team-card" key={index}>
              <img src={member.image} alt={member.name} className="team-avatar" />
              <div className="team-info">
                <h2>{member.name}</h2>
                <p className="team-title">{member.title}</p>
                <button className="see-bio-button" onClick={() => handleShowDetails(index)}>
                  See bio
                </button>
              </div>
            </div>
          ))}
          {showDetails !== null && (
            <BioModal
              member={teamMembers[showDetails]}
              onClose={handleCloseDetails}
            />
          )}
        </div>
        <BackButton />
      </div>
    </div>
  );
};

export default OurTeam;
