import React from 'react';
import './contact.css';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import BackButton from '../../components/BackButton/BackButton';
import Header from '../../components/Header/Header';

const Contact = () => {
    return (
        <div>
            <Header />
            <div className="contact-container">
                <h1>Contact</h1>
                <div className="contact-info">
                    <p>Noddle Care Inc.</p>
                    <p>Email: support@noddlefamilies.com</p>
                    <p>Vancouver, Canada</p>
                    {/* <div className="social-media">
                        <FaLinkedin className="social-icon" />
                        <FaTwitter className="social-icon" />
                    </div> */}
                </div>
                <BackButton buttonColor="rgb(155, 210, 196)" textColor="black" />
            </div>
        </div>
    );
};

export default Contact;
