import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../logo/NoddleLogo.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header>
      <nav className="navbar">
        <Link to="/" className="logo-link">
          <div className="logo">
            <img src={logo} alt="Noddle Care Logo" />
          </div>
        </Link>
        <button
          className="hamburger"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle navigation"
        >
          &#9776;
        </button>
        <div className={`navbar-links ${isMenuOpen ? "open" : ""}`}>

          <button className="cancel-button" onClick={() => setIsMenuOpen(false)}>X</button>
          <Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link>
          <Link to="/services" onClick={() => setIsMenuOpen(false)}>Mental Health Care</Link>
          <Link to="/about" onClick={() => setIsMenuOpen(false)}>Our Team</Link>
          <Link to="/contact" onClick={() => setIsMenuOpen(false)}>Contact</Link>
        </div>
        <div className="tag">
          <h1>Social Mental Wellness for Families</h1>
        </div>
      </nav>
    </header>
  );
};

export default Header;
