import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import logo from '../../assets/coming_Soon.png';

const ComingSoon = () => {
    const navigate = useNavigate(); // Hook for navigation

    // Function to handle click event on the "Back" button
    const goBack = () => {
        navigate('/home'); // Navigates to the homepage
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column', // Stack elements vertically
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
            paddingTop: '80px',
            padding: '20px',
        }}>
            <img src={logo} alt="Coming Soon" style={{ maxWidth: '100%', maxHeight: '100%', marginBottom: '20px' }} />
            <button onClick={goBack} style={{
                padding: '10px 20px',
                backgroundColor: '#8F8ECA',
                fontSize: '1rem',
                cursor: 'pointer',
                borderRadius: '20px',

            }}>Back</button>
        </div>
    );
};

export default ComingSoon;
